import axios from 'axios';
import conf from "@/common/conf"
import common from "@/common/common"
const instance = axios.create({
  baseURL: conf.apiUrl, // 替换为你的 API 基础 URL
  timeout: 60000 // 设置请求超时时间
});

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (response) => {
    // console.log(response.data.code)
    if (response.status == 200) {

      // 返回的内部如果不是200的话就reject并且进行弹窗判断
      if (response.data.code !== 200) {
        return Promise.resolve(response.data.code)
      }
      response.data.data = common.decodes(response.data.data)
      
      Promise.resolve(response.data)
    } else {
      response.data.data = common.decodes(response.data.data)
      Promise.reject(response.data);
    }
    return response;
  },
  (error) => {

  }
);

export default instance;