import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from '@/store';

import Vant from "vant";
import "vant/lib/index.css";
// import VConsole from 'vconsole'
// new VConsole()
Vue.prototype.$onLaunched = new Promise((resolve) => {
  // 将 resolve 方法挂载到 Vue.prototype，以便在 App.vue 中调用
  Vue.prototype.$resolveOnLaunched = resolve
})

Vue.use(Vant);
Vue.config.productionTip = false;
new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
