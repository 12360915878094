import axios from '@/request/axios'
import conf from "@/common/conf"
import define from "@/common/define"
export function postAction(url, parameter) {
    let time = Date.parse(new Date()) / 1000
    let param = define.defineParam(parameter, time)
    const formData = new URLSearchParams()
    formData.append('param', param)
    formData.append('time', time)
    return axios({
        url: conf.vipApi + url,
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
            // Authorization: `Bearer ${store.getters.doneToken}` // 如果需要 token
        }
    })
}

export function getAction(url, data) {
    let time = Date.parse(new Date()) / 1000
    let param = define.defineParam(parameter, time)
    const formData = new URLSearchParams()
    formData.append('param', param)
    formData.append('time', time)
    return axios({
        url: conf.vipApi + url,
        method: 'get',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
            // Authorization: `Bearer ${store.getters.doneToken}` // 如果需要 token
        },
        params:formData
    })
}


