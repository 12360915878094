/**
 * 是否为空（empty: '', '0', 0, false, null, NaN, undefined, {}, []）
 * 
 * @param mix param 需要判断是否为空的参数
 * 
 * @return boolean true:空，false:非空
 */

import decode from '@/common/decode.js'
const isEmpty = (param) => {
	let res = false

	if (param) {
		let type = typeof param

		if (type === 'string') {
			if (param === '0') {
				res = true
			}
		}

		if (type === 'object') {
			let paramToStr = JSON.stringify(param)

			if (paramToStr === '{}' || paramToStr === '[]') {
				res = true
			}
		}
	} else {
		res = true
	}

	return res
}

const takeOption = (base64) => {
	const option = decodeURIComponent(atob(base64))
	let option2 = String(option.replace(/(\w+):/g, '"$1":').replace(/:undefined/g, ':null').replace(/:(.+?)([,}])/g,':"$1"$2')) 
	let res = JSON.parse(option2)
	return res
}

/**
 * 对象转get参数
 * 
 * @param object obj 需要转换的对象
 * 
 * @return string 转换完的get参数
 */
const objToGetParam = (obj) => {
	if (!isEmpty(obj)) {
		// 非空，则循环拼接
		let str = '?'

		for (let key in obj) {
			if (str !== '?') {
				str += '&'
			}

			if (key === 'ac') {
				str = str + key + '=' + encodeURIComponent(obj[key])
			} else {
				str = str + key + '=' + obj[key]
			}
		}

		return str
	} else {
		// 若空对象，直接返回空字符串
		return ''
	}
}


const decodes = (text) => {
	let arr = JSON.parse(text)
	if(decode.defineParam(arr.param, arr.time).indexOf('{')==0||decode.defineParam(arr.param, arr.time).indexOf('[')==0){
		return JSON.parse(decode.defineParam(arr.param, arr.time))
	}else{
		return decode.defineParam(arr.param, arr.time)
	}
	
}

/**
 * 错误码转提示语
 * 
 * @param int errCode 错误码
 * 
 * @return string 提示文字
 */
const errCodeToMsg = (errCode) => {
	let msg = '接口异常，请联系管理员(' + errCode + ')！'

	switch (errCode) {
		case 201:
			msg = '数据异常！'
			break
		case 300:
			msg = '上级数据异常！'
			break
		case 301:
			msg = '会员不存在！'
			break
		case 302:
			msg = '请联系管理员审核-100'
			break
		case 303:
			msg = '权限异常-101'
			break
		case 304:
			msg = '权限异常-102'
			break
		case 305:
			msg = '权限异常-103'
			break
		case 306:
			msg = '手机号已注册！'
			break
		case 307:
			msg = '短信发送已到上限！'
			break
		case 308:
			msg = '短信发送异常！'
			break
		case 309:
			msg = '无微信完整授权！'
			break
		case 310:
			msg = '用户已存在！'
			break
		case 311:
			msg = '验证码错误！'
			break
		case 312:
			msg = '用户异常，注册失败！'
			break
		case 313:
			msg = '账号异常-104'
			break
		case 314:
			msg = '权限异常-120'
			break
		case 315:
			msg = '绑定异常！'
			break
		case 320:
			msg = '状态异常-105'
			break
		case 321:
			msg = '权限异常-106'
			break
		case 322:
			msg = '已答题正确，请勿重复提交！'
			break
		case 323:
			msg = '答题次数已用完！'
			break
		case 324:
			msg = '状态异常-107'
			break
		case 325:
			msg = '问题不存在！'
			break
		case 326:
			msg = '该直播题库已更新，请刷新！'
			break
		case 327:
			msg = '播放进度记录失败！'
			break
		case 328:
			msg = '活动分类不存在！'
			break
		case 329:
			msg = '二维码失效,请联系上级重新生成！'
			break
		case 450:
			msg = '二维码失效！'
			break
		case 451:
			msg = '短信条数不足，无法发送！'
			break
	}

	return msg
}

/**
 * 字符串日期，new Date(strTime) 时，IOS低版本不兼容，转换解决
 * 
 * @param string strTime 原时间（例：2023-02-10 10:00:00）
 * 
 * @return string 转换完格式（例：2023/02/10 10:00:00）
 */
const strTimeAmend = (strTime) => {
	return strTime.replace(/-/g, '/')
}

/**
 * 时间戳（秒级）转字符串日期
 * 
 * @param int time 需要转换的时间戳（秒级）
 * 
 * @return string 转换后的日期
 */
const timeToDate = (time) => {
	let date = new Date(time * 1000)
	let y = date.getFullYear(),
		m = date.getMonth() + 1,
		d = date.getDate(),
		h = date.getHours(),
		i = date.getMinutes(),
		s = date.getSeconds()
	if (m < 10) {
		m = '0' + m
	}
	if (d < 10) {
		d = '0' + d
	}
	if (h < 10) {
		h = '0' + h
	}
	if (i < 10) {
		i = '0' + i
	}
	if (s < 10) {
		s = '0' + s
	}
	var t = y + '-' + m + '-' + d + ' ' + h + ':' + i + ':' + s

	return t
}

/**
 * 加解密（调用encode、decode）
 * 
 * @param string e 需要加解密的值
 * 
 * @return string 加解密后的值
 */
const BaseSelf = {
	_keyStr: "CDnEFGHI6JKMklRST8UVWoXY/Zabc7defghijmpq=Brstu+vwxAyzL012NPQ3459O",

	encode: function(e) {
		var t = "";
		var n, r, i, s, o, u, a;
		var f = 0;
		e = BaseSelf._utf8_encode(e);
		while (f < e.length) {
			n = e.charCodeAt(f++);
			r = e.charCodeAt(f++);
			i = e.charCodeAt(f++);
			s = n >> 2;
			o = (n & 3) << 4 | r >> 4;
			u = (r & 15) << 2 | i >> 6;
			a = i & 63;
			if (isNaN(r)) {
				u = a = 64
			} else if (isNaN(i)) {
				a = 64
			}
			t = t + this._keyStr.charAt(s) + this._keyStr.charAt(o) + this._keyStr.charAt(u) + this._keyStr
				.charAt(a)
		}

		t = t.replaceAll('a', '!')
		t = t.replaceAll('b', '@')
		t = t.replaceAll('c', '#')
		t = t.replaceAll('1', '$')
		t = t.replaceAll('2', '%')
		t = t.replaceAll('3', '-')

		t = t.replaceAll('!', 'a1')
		t = t.replaceAll('@', 'a2')
		t = t.replaceAll('#', 'a3')
		t = t.replaceAll('$', 'b1')
		t = t.replaceAll('%', 'b2')
		t = t.replaceAll('-', 'b3')
		t = t.replaceAll('=', 'c1')
		t = t.replaceAll('+', 'c2')
		t = t.replaceAll('/', 'c3')

		return t
	},
	decode: function(e) {
		e = e.replaceAll('a1', '!')
		e = e.replaceAll('a2', '@')
		e = e.replaceAll('a3', '#')
		e = e.replaceAll('b1', '$')
		e = e.replaceAll('b2', '%')
		e = e.replaceAll('b3', '-')
		e = e.replaceAll('c1', '=')
		e = e.replaceAll('c2', '+')
		e = e.replaceAll('c3', '/')

		e = e.replaceAll('!', 'a')
		e = e.replaceAll('@', 'b')
		e = e.replaceAll('#', 'c')
		e = e.replaceAll('$', '1')
		e = e.replaceAll('%', '2')
		e = e.replaceAll('-', '3')

		var t = "";
		var n, r, i;
		var s, o, u, a;
		var f = 0;
		e = e.replace(/[^A-Za-z0-9+/=]/g, "");
		while (f < e.length) {
			s = this._keyStr.indexOf(e.charAt(f++));
			o = this._keyStr.indexOf(e.charAt(f++));
			u = this._keyStr.indexOf(e.charAt(f++));
			a = this._keyStr.indexOf(e.charAt(f++));
			n = s << 2 | o >> 4;
			r = (o & 15) << 4 | u >> 2;
			i = (u & 3) << 6 | a;
			t = t + String.fromCharCode(n);
			if (u != 64) {
				t = t + String.fromCharCode(r)
			}
			if (a != 64) {
				t = t + String.fromCharCode(i)
			}
		}
		t = BaseSelf._utf8_decode(t);
		return t
	},
	_utf8_encode: function(e) {
		e = e.replace(/rn/g, "n");
		var t = "";
		for (var n = 0; n < e.length; n++) {
			var r = e.charCodeAt(n);
			if (r < 128) {
				t += String.fromCharCode(r)
			} else if (r > 127 && r < 2048) {
				t += String.fromCharCode(r >> 6 | 192);
				t += String.fromCharCode(r & 63 | 128)
			} else {
				t += String.fromCharCode(r >> 12 | 224);
				t += String.fromCharCode(r >> 6 & 63 | 128);
				t += String.fromCharCode(r & 63 | 128)
			}
		}
		return t
	},
	_utf8_decode: function(e) {
		var t = "";
		var n = 0;
		var r, c1, c2, c3;
		r = c1 = c2 = c3 = 0
		while (n < e.length) {
			r = e.charCodeAt(n);
			if (r < 128) {
				t += String.fromCharCode(r);
				n++
			} else if (r > 191 && r < 224) {
				c2 = e.charCodeAt(n + 1);
				t += String.fromCharCode((r & 31) << 6 | c2 & 63);
				n += 2
			} else {
				c2 = e.charCodeAt(n + 1);
				c3 = e.charCodeAt(n + 2);
				t += String.fromCharCode((r & 15) << 12 | (c2 & 63) << 6 | c3 & 63);
				n += 3
			}
		}
		return t
	}
}

// 输出
export default {
	isEmpty,
	objToGetParam,
	errCodeToMsg,
	strTimeAmend,
	timeToDate,
	BaseSelf,
	decodes,
	takeOption
}