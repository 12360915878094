import conf from "@/common/conf.js"

/**
 * 微信网页授权，跳转到微信授权页
 * 
 * @param appid string 微信公众号 appid
 * @param scope string snsapi_base或snsapi_userinfo
 * 
 * @return none 直接跳转，不返回数据
 */
const h5Auth = (appid, scope, ac, url, type, ic) => {
	// 微信 redirect_uri
	let redirectUri = ''
	let urlstr = atob(url)
	let aUrl = new URL(urlstr).hostname
	if (type == 'ac') {
		redirectUri = conf.vipUrl + '/' + aUrl + '/?ac=' + ac
	} else {
		redirectUri = conf.vipUrl + '/' + aUrl + '/?gr=' + ac + '&ic=' + ic
	}
	redirectUri = encodeURIComponent(redirectUri)
		.replace(/!/g, '%21').replace(/'/g, '%27').replace(/\(/g, '%28')
		.replace(/\)/g, '%29').replace(/\*/g, '%2A').replace(/%20/g, '+')
	// 微信跳转地址
	let wechatUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid='
		+ appid + '&redirect_uri=' + redirectUri + '&response_type=code&scope='
		+ scope + '&state=1#wechat_redirect'

	// 记录当前访问的授权方式
	localStorage.setItem('h5AuthScope', scope)
	// 跳转微信授权
	window.location.href = wechatUrl
}

// 输出
export default {
	h5Auth
}
