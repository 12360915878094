import arAes from '@/js_sdk/ar-aes/ar-aes.js'

/**
 * 定义
 */
const defineStr = {
	'D': 65,
	'n': 66,
	'E': 67,
	'F': 68,
	'3': 69,
	'G': 70,
	'H': 71,
	'j': 72,
	'k': 73,
	'I': 74,
	'6': 75,
	'J': 76,
	'K': 77,
	'M': 78,
	'N': 79,
	'P': 80,
	'Q': 81,
	'R': 82,
	'S': 83,
	'p': 84,
	'q': 85,
	'=': 86,
	'B': 87,
	'T': 88,
	'8': 89,
	'U': 90,
	'V': 97,
	'W': 98,
	'o': 99,
	'/': 100,
	'Z': 101,
	'a': 102,
	'b': 103,
	'c': 104,
	'7': 105,
	'd': 106,
	'e': 107,
	'f': 108,
	'g': 109,
	'h': 110,
	'i': 111,
	'l': 112,
	'm': 113,
	'r': 114,
	's': 115,
	't': 116,
	'u': 117,
	'+': 118,
	'v': 119,
	'w': 120,
	'C': 121,
	'x': 122,
	'A': 48,
	'y': 49,
	'z': 50,
	'L': 51,
	'0': 52,
	'1': 53,
	'2': 54,
	'X': 55,
	'Y': 56,
	'4': 57,
	'5': 43,
	'9': 47,
	'O': 61
}

/**
 * 转码
 */
const transcode = (text, time) => {
	let textLen = text.length
	let charNum = 0
	let result = ''

	for (let i = 0; i < textLen; i++) {
		charNum = ((time * 2 - 1703132600) % (textLen + i)) % 3

		switch (charNum) {
			case 0:
				if ((i % 2) === 0) {
					result += text[i]
				} else {
					result += String.fromCharCode(defineStr[text[i]])
				}
				break
			case 1:
				if ((i % 2) === 1) {
					result += text[i]
				} else {
					result += String.fromCharCode(defineStr[text[i]])
				}
				break
			case 2:
				if ((i % 3) === 0) {
					result += String.fromCharCode(defineStr[text[i]])
				} else {
					result += text[i]
				}
				break
		}
	}

	return result
}

/**
 * 转参
 */
const defineParam = (text, time) => {
	return arAes.AES.decrypt(transcode(text, time), 'xkT31Q0BXvfIWJ05', 'nnLkVLht2DwDpo9c')
}
// decode.defineParam(this.param, this.time)
// 输出
export default {
	defineParam
}