// src/store.js
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
const store = new Vuex.Store({
	state: {
		// 需要跳转返回的path(最后一次记录的)
		backPath: '',
		// token
		token: '',
		// 用户信息
		userinfo: {},
		// 用户信息（临时的）
		userCodeData: {},
		// appid（公众号的）
		appid: '',
		// 签到小程序
		signWechat: {
			appid: '',
			path: ''
		},
		// cdn
		cdn: '',
		// 系统参数
		basics: {}		
	},
	getters: {
		// 根据配置获取cdn
		getCdn(state) {
			return state.cdn
		}
	},
	mutations: {
		// 需要跳转返回的path
		modifyBackPath(state, val) {
			state.backPath = val ;
		},
		// token
		modifyToken(state, val) {
			state.token = val
		},
		// 用户信息
		modifyUserinfo(state, val) {
			state.userinfo = val ;
		},
		// 用户信息（临时的）
		modifyUserCodeData(state, val) {
			state.userCodeData = val ;
		},
		// appid
		modifyAppid(state, val) {
			state.appid = val ;
		},
		// 签到小程序
		modifySignWechat(state, val) {
			state.signWechat = val ;
		},
		// cdn
		modifyCdn(state, val) {
			state.cdn = val ;
		},
		// 系统参数
		modifyBasics(state, val) {
			state.basics = val ;
		}
	}
})

export default store