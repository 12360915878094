// 读取配置json
let xhr = new XMLHttpRequest()
xhr.open('GET', '/param.json', false)
xhr.overrideMimeType("text/html;charset=utf-8")
xhr.send(null)
let param = JSON.parse(xhr.responseText)
// content-type值
const contentType = 'application/x-www-form-urlencoded'

// cdn
const cdn = param.cdn

// 地址
const vipUrl = param.vipUrl

// api地址
const vipApi = param.vipApi

// 首页图
const indexImg = param.indexImg



// vip3
const vip3 = param.vip3
// 输出
export default {
	contentType,
	cdn,
    vipUrl,
	vipApi,
	indexImg,
	vip3
}
