<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>

import { postAction, getAction } from "@/request/universal"
import conf from "@/common/conf.js"
import common from "@/common/common"
import store from '@/store'
import { Dialog } from 'vant';
(function () {
  if (typeof WeixinJSBridge == "object" && typeof WeixinJSBridge.invoke == "function") {
    handleFontSize();
  } else {
    document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
  }

  function handleFontSize() {
    // 设置网页字体为默认大小
    WeixinJSBridge.invoke('setFontSizeCallback', {
      'fontSize': 0
    });
    WeixinJSBridge.call('hideOptionMenu');
    // 设置网页字体大小的事件
    WeixinJSBridge.on('menu:setfont', function () {
      WeixinJSBridge.invoke('setFontSizeCallback', {
        'fontSize': 0
      });
    });
  }
})();
export default {
  async mounted() {
    if (!localStorage.getItem('href')) {
      localStorage.setItem('href', window.location.href)
    }
    if (window.location.href.indexOf('?') == -1) {
      let a = true
      const res = await postAction('/ix/sl/vd', { url:window.location.href})
      if (res == -1) {
        Dialog.alert({
          message: '链接不存在',
          showConfirmButton: false
        })
        a = false
        return a
      } else if (res == 0) {
        Dialog.alert({
          message: '链接已过期',
          showConfirmButton: false
        })
        a = false
        return a
      }
      if (!a) {
        return false
      }
    }
    const urlParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlParams.entries());

    let option = {}
    if (window.location.href.indexOf('?') == -1) {
      const encodedParam = window.location.pathname.split('/').pop();
      let param = {
        ac: encodedParam
      }
      const res = await postAction('/ix/ac', param)
      if (res == 602) {
        Dialog.alert({
          message: '权限异常-108',
          showConfirmButton: false
        })
        return false
      } else {
        let data = res.data.data
        option.query = common.takeOption(data)
      }
    } else {
       option.query = params
    }

    // 获取系统参数并登录
    this.systemAndAuth(option)
  },
  methods: {
    async systemAndAuth(option) {
      // cdn地址
      store.commit('modifyCdn', conf.cdn)
      let param = {
        groupId: 0
      }
      if (option.query.ac) {

        // 活动传参方式
        let ac = common.BaseSelf.decode(option.query.ac)
        ac = ac.split('&')

        if (!ac[2] || ac[2] !== 'air') {
          Dialog.alert({
            message: '权限异常-108',
          }).then(() => {

          });
          return false
        }
        param.groupId = ac[0]
      }
      if (option.query.gr) {
        param.groupId = option.query.gr
      }
      if (localStorage.getItem('groupId')) {
        param.groupId = localStorage.getItem('groupId')
      }
      const res = await postAction('/ix/ss', param)
      let data = res.data.data
      if (data.Rule && data.Wxset && data.Wxset.appid) {
        store.commit('modifyAppid', data.Wxset.appid)
      } else {
        Dialog.alert({
          message: '账号异常-109',
        }).then(() => {

        });
        // 返回
        return false
      }

      if (data.Rule.appid) {
        store.commit('modifySignWechat', {
          appid: data.Rule.appid,
          path: data.Rule.signin
        })
      }
      if (option.query.code) {
        this.$resolveOnLaunched()
        return false
      }
      this.$resolveOnLaunched()
      if (option.query.ac) {
        this.$router.push({
          path: '/',
          query: {
            ac: option.query.ac
          }
        })
      } else {
        this.$router.push({
          path: '/',
          query: {
            gr: option.query.gr,
            ic: option.query.ic,
          }
        })
      }

    }
  }
}
</script>
<style lang="scss">
html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
</style>
