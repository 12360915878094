<template>
  <div class="home">
    <div v-if="tq && !code" class="weathersss">
      <iframe src='https://www.caiyunapp.com/h5/' style="width: 100%;height: 100vh;" frameborder="0"></iframe>
      <div class="tqh5" v-if="isPcWeChat" @click="showDetail">查看详情</div>
    </div>
    <div class="content" v-else-if="!code && !tq">
      <div style="height:1px; width: 100%;"></div>
      <div class="ssl">
        <!-- <img :src="`${baseUrl}/vip/pink/index_box.png`" mode=""></img> -->
      </div>
      <div class="btns" @click="submitAuth()">
        查看详情
      </div>
      <div class="readio">
      </div>
    </div>
    <iframe v-else ref='iframe' style="width: 100%;height: 100vh;" src='' frameborder="0"></iframe>
  </div>
</template>

<script>
import { Toast } from 'vant';
import wechat from "@/common/wechat.js"
import store from '@/store'
import conf from '@/common/conf'
export default {
  name: "Index",
  data() {
    return {
      ac: "",
      submitAllow: true,
      code: '',
      checked: true,
      type: true,
      baseUrl: '',
      vip3: '',
      tq: false,
      gr: '',
      ic: '',
      isPcWeChat: ''
    };
  },
  async mounted() {
    await this.$onLaunched
    const ua = navigator.userAgent.toLowerCase();
    const isWeChat = ua.includes('micromessenger'); // 微信
    const isWxWork = ua.includes('wxwork');        // 企业微信
    this.isPcWeChat = ua.includes('windowswechat'); // PC微信
    const isMobile = /android|iphone|ipod|ipad|harmony|phone|openharmony/i.test(ua);
    if ((isMobile && isWeChat && !isWxWork)) {
      this.tq = false
    } else {
      this.tq = true
    }
    this.baseUrl = store.getters.getCdn
    this.vip3 = conf.vip3
    const urlParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlParams.entries());
    if (this.$route.query.gr || params.gr) {
      this.gr = this.$route.query.gr ? this.$route.query.gr : params.gr
      this.ic = this.$route.query.ic ? this.$route.query.ic : params.ic
    } else {
      if (this.$route.query.ac) {
        this.ac = this.$route.query.ac
      } else {
        this.ac = params.ac
      }
    }
    if (params.code) {
      this.code = params.code
      this.$nextTick(() => {
        if (this.ac) {
          this.$refs.iframe.src = this.vip3 + 'pages/index/login?code=' + this.code + '&ac=' + this.ac
        } else if (this.gr) {
          this.$refs.iframe.src = this.vip3 + 'pages/index/login?code=' + this.code + '&gr=' + this.gr + '&ic=' + this.ic
        }
      })
    }

  },
  methods: {
    showDetail() {
      this.tq = false
      if (this.ac) {
        wechat.h5Auth(store.state.appid, 'snsapi_userinfo', this.ac, btoa(window.location.href), 'ac', '')
      } else {
        wechat.h5Auth(store.state.appid, 'snsapi_userinfo', this.gr, btoa(window.location.href), 'gr', this.ic)
      }
    },
    submitAuth() {
      if (this.submitAllow) {
        if (!this.checked) {
          Toast('请先阅读并同意《用户协议》《隐私协议》');
          return
        }
        // 防重复提交
        this.submitAllow = false
        // 提交
        if (this.ac) {
          wechat.h5Auth(store.state.appid, 'snsapi_userinfo', this.ac, btoa(window.location.href), 'ac', '')
        } else {
          wechat.h5Auth(store.state.appid, 'snsapi_userinfo', this.gr, btoa(window.location.href), 'gr', this.ic)
        }
        this.submitAllow = true
      }
    },
  },
};
</script>
<style scoped lang="scss">
.weathersss {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .tqh5 {
    position: absolute;
    width: calc(70px) !important;
    height: 12px;
    font-size: 4px;
    border-radius: 48px;
    margin-top:100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #4F90B8;
    cursor: pointer;
  }
}

.content2 {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  background: #fff;


  .ssl {
    width: 80%;
    // height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    // border: #02CD7C 2px solid;
    // border-radius: 15px;
    // color: #02CD7C;
    margin: 50px auto 100px;
    margin-bottom: 120px;
    height: 200px;

    img {
      // width: 00px;
      height: 200px;
      margin-right: 20px;
    }
  }

  .see_box {
    width: 100%;
    height: 370px;
    // background-color: #F7F7F7;
    margin: 0 auto;
    font-family: Microsoft YaHei;
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    color: #586772;

    .time {
      font-size: 34px;
    }

    .dad {
      font-size: 105px;
      font-weight: 600;
    }

    .times {
      font-size: 47px;
    }
  }

  .item-img {
    width: 420.14px;
    height: 411.81px;
    margin-left: 150px;
  }

  .btns {
    width: 80%;
    height: 50px;
    background-color: #FF8748;
    border-radius: 50px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 60px auto;
  }

  .readio {
    width: 100%;
    margin: 200px auto 0;
    display: flex;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    // /deep/.uni-checkbox-input {
    // 	color: #fff !important;
    // 	background-color: #02CD7C;
    // 	border: none;
    // }
  }
}
</style>
